import { Controller } from "@hotwired/stimulus"

import intlTelInput from 'intl-tel-input'
import utils from "intl-tel-input/build/js/utils.js"

export default class extends Controller {
  static targets = ['input', 'hidden']

  connect() {
    var controller = this

    this.iti = intlTelInput(this.inputTarget, {
      utilsScript: utils, 
      preferredCountries: ['ch', 'de', 'at', 'fr'],
      initialCountry: 'ch'
    })

    var event = new Event('change');
    this.inputTarget.addEventListener("countrychange", function() {
      controller.inputTarget.dispatchEvent(event)
    })
  }

  disconnect() {
    this.iti.destroy()
  }

  setNumber() {
    var number = this.iti.getNumber()
    this.hiddenTarget.value = number
  }
}
