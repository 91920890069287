import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "row" ]

  toggle() {
    var button = event.target
    var row_id = button.value

    var selected_row = this.element.querySelector(`[data-content-box-target='row'][data-id='${row_id}']`)

    // Hide all rowTargets
    this.rowTargets.forEach((el) => {
      el.classList.add('hidden')
    })

    // Show selected row
    if (selected_row) {
      selected_row.classList.remove('hidden')
    }

    // Enable / disable input fields
    this.rowTargets.forEach((row) => {
      let inputs = row.querySelectorAll('input, select, textarea')
      inputs.forEach((input) => {
        if (row.classList.contains('hidden')) {
          input.disabled = true
        } else {
          input.disabled = false
        }
      })
    })
  }
}
