import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['progress']
  static values = { key: String, filename: String, redirect: String }

  connect() {
    this._cableSetup()
  }

  _cableSetup() {
    this.subscription = consumer.subscriptions.create(
      {
        channel: "PayslipExportChannel",
        key: this.keyValue
      },
      {
        connected: this._cableConnected.bind(this),
        disconnected: this._cableDisconnected.bind(this),
        received: this._cableReceived.bind(this)
      }
    )
  }

  _cableConnected() {
  }

  _cableDisconnected() {
  }

  _cableReceived(data) {
    if (data.zip == undefined) {
      let width = parseInt(data.progress).toString() + '%'
      this.progressTarget.style.width = width
      this.progressTarget.innerHTML = width
    } else {
      // Decode zip data
      let decoded_zip_data = window.atob(data.zip)
      let zip_data = this.str2bytes(decoded_zip_data)

      // Save file
      let filename = this.filenameValue
      let blob = new Blob([zip_data], {
        type: "application/zip"
      })
      saveAs(blob, filename)

      // Reload page
      Turbo.visit(this.redirectValue, { action: 'replace' })

      // Kill cable
      this.subscription.unsubscribe()
      consumer.disconnect()
      delete this.subscription
    }
  }

  str2bytes = (str) => {
    const bytes = new Uint8Array(str.length);
    let i = 0;

    while (i < str.length) {
      bytes[i] = str.charCodeAt(i);
      i++;
    }

    return bytes;
  }
}
