import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "contentField", "collapsedContent", "expandedContent" ]

  expand(event) {
    event.preventDefault();
    this.collapsedContentTarget.classList.add("hidden")
    this.expandedContentTarget.classList.remove("hidden")
    this.contentFieldTarget.focus()
  }

  collapse() {
    this.collapsedContentTarget.classList.remove("hidden")
    this.expandedContentTarget.classList.add("hidden")
  }

  keypress(event) {
    if (event.key === "Escape") {
      this.collapse(event)
    }
  }

  submit(event) {
    if (this.contentFieldTarget.value === "") {
      event.preventDefault();
    }
  }

  cleanup() {
    this.contentFieldTarget.value = ""
    this.collapse()
  }
}

