import { Controller } from "@hotwired/stimulus"
import Glide from '@glidejs/glide'

export default class extends Controller {
  connect() {
    this.glide = new Glide(this.element, {
      type: 'slider',
      startAt: 0,
      perView: 1,
      autoplay: 4000,
      hoverpause: false,
      gap: 10,
    })
    this.glide.mount()
  }

  disconnect() {
    if (this.glide) {
      this.glide.destroy()
    }
  }
}
