import { Controller } from "@hotwired/stimulus"
import Headroom from "headroom.js"

export default class extends Controller {
  static targets = [ "navbar" ]

  connect() {
    var headroom = new Headroom(this.navbarTarget, { offset: 70, tolerance: 0 })
    headroom.init()
  }
}
