import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['wrapper']

  add() {
    event.preventDefault()

    let time   = new Date().getTime()
    let regexp = new RegExp(this.data.get('id'), 'g')

    let html = this.data.get('html').replace(regexp, time)

    // Create tmp div
    var div = document.createElement('div')
    div.innerHTML = html

    // Append content of div
    this.wrapperTarget.appendChild(div.childNodes[0])
  }
}
