import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input']

  toggle() {
    let clickedTarget = event.target

    this.inputTargets.forEach((target) => {
      let content = document.getElementById(target.dataset.toggleId)
      if (content == null) {
        return
      }

      if (target == clickedTarget) {
        content.querySelectorAll('input').forEach((el) => {
          el.disabled = false
        })
        content.classList.remove('hidden')
        content.querySelector('input').select()
      } else {
        content.classList.add('hidden')
        content.querySelectorAll('input').forEach((el) => {
          el.disabled = true
        })
      }
    })

  }
}
