import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "body", "icon" ]

  static values = { degree: String }

  toggle(event) {
    event.preventDefault();
    var body = this.bodyTarget
    var icon = this.iconTarget

    if (body.style.maxHeight) {
      body.style.maxHeight = null;
      icon.classList.replace(`rotate-${this.degreeValue}`, "rotate-0")
    } else {
      body.style.maxHeight = body.scrollHeight + "px"
      icon.classList.replace("rotate-0", `rotate-${this.degreeValue}`)
    }
  }
}
