import { Controller } from "@hotwired/stimulus"

import Swiper, { Pagination, Autoplay, Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';

export default class extends Controller {

  static targets = ["testimonialContainer", "magazineContainer"]

  connect() {
    if (this.hasTestimonialContainerTarget) {
      this.testimonialsSwiper
    }

    if (this.hasMagazineContainerTarget) {
      this.magazineSwiper
    }
  }

  get magazineSwiper() {
    Swiper.use([Navigation, Autoplay])

    new Swiper(this.magazineContainerTarget, {
      autoplay: {
        delay: 4000,
      },
      speed: 400,
      spaceBetween: 100,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    });
  }

  get testimonialsSwiper() {
    Swiper.use([Pagination, Autoplay])

    new Swiper(this.testimonialContainerTarget, {
      centeredSlides: false,
      autoplay: false,
      loop: false,
      initialSlide: 0,
      watchOverflow: true,
      speed: 1000,
      spaceBetween: 52,
      pagination: {
        el: '.swiper-pagination-testimonials',
        clickable: true,
      },
      breakpoints: {
        // when window width is <= 480px
        320: {
          slidesPerView: 1,
          spaceBetween: 30
        },
        // when window width is <= 640px
        480: {
          slidesPerView: 1,
          spaceBetween: 30
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 30
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 30
        }
      }
    });
  } // testimonialSwiper
}
