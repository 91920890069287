import { Controller } from "@hotwired/stimulus"

import Rails from "@rails/ujs";

export default class extends Controller {

  checkTodo(event) {
    var data = {
      checked: event.target.checked,
      list_name: event.target.dataset.checklist,
      list_id: event.target.dataset.checklistId
    }

    Rails.ajax({
      type: "patch",
      url: this.data.get('url'),
      data: new URLSearchParams(data).toString()
    })
  }
}
