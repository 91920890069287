// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "trix"
import "@rails/actiontext"

import "./controllers"
import "file-saver"

// Run Stimulus teardown before navigating to another page
document.addEventListener('turbo:before-visit', () => {
  Stimulus.controllers.forEach(controller => {
    if (typeof controller.teardown === 'function') {
      controller.teardown()
    }
  })
})
