import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['destroy']

  remove(event) {
    event.preventDefault()

    if (this.hasDestroyTarget) {
      this.element.querySelectorAll('[required]').forEach((el) => {
        el.removeAttribute('required')
      })

      this.destroyTarget.value = 1
      this.element.classList.add('hidden')
    } else {
      this.element.remove()
    }
  }
}
