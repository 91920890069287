import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"
import {enter, leave} from "el-transition"

export default class extends Controller {
  static targets = ["menu", "mobileMenu"]

  connect() {
    useClickOutside(this)
  }

  disconnect() {
    this.hideAll()
  }

  toggle(event) {
    this.menuTargets.filter(e => e !== event.currentTarget.nextElementSibling).forEach(e => e.classList.add("hidden"))

    if(event.currentTarget.nextElementSibling.classList.contains("hidden")) {
      enter(event.currentTarget.nextElementSibling)
    } else {
      leave(event.currentTarget.nextElementSibling)
    }
  }

  toggleMobileMenu() {
    if(this.mobileMenuTarget.classList.contains("hidden")) {
      enter(this.mobileMenuTarget)
    } else {
      leave(this.mobileMenuTarget)
    }
  }

  clickOutside() {
    this.hideAll()
  }

  hideAll() {
    this.menuTargets.forEach(e => e.classList.add("hidden"))
    this.mobileMenuTarget.classList.add("hidden")
  }
}

