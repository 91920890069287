import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "video" ]

  connect() {
    let observer = new IntersectionObserver((entries) => { 
      entries.forEach(entry => {
        if(entry.isIntersecting){
          entry.target.play();
        }
      });
    }, {rootMargin: "0px 0px -200px 0px"});
    let videos = this.videoTargets
    videos.forEach(video => { observer.observe(video) });
  }
}
