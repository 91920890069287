import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ["dataURL", "textField", "nameLabel"]

  testMessage(event) {
    event.preventDefault()
    let telegramId = this.textFieldTarget.value
    Rails.ajax({
      type: "post",
      url: `${this.dataURLTarget.value}/sendMessage`,
      data: `chat_id=${telegramId}&text=Telegram setup successfull!`,
      success: function() { alert("Test message sent!") }
    })
  }

  getId(event) {
    event.preventDefault()
    let textField = this.textFieldTarget
    let nameLabel = this.nameLabelTarget
    Rails.ajax({
      type: "get",
      url: `${this.dataURLTarget.value}/getUpdates`,
      success: function(data) { 
        var id = data.result.pop().message.from.id
        var name = data.result.pop().message.from.first_name
        textField.value = id
        nameLabel.textContent = name
      }
    })
  }
}
