import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["repeatingField", "repeatingGroup", "timeField", "wholeDayBox"]

  connect() {
    this.switchRecurrence(this.defaultRecurrence)
    if (this.wholeDayBoxTarget.checked == true) {
      this.disableTimeFields()
    }
  }

  changeRecurrence(event) {
    this.switchRecurrence(event.target.value)
  }

  switchRecurrence(recurrence) {
    if (recurrence == "repeating") {
      this.enableRepeatingFields()
    }
    if (recurrence == "once") {
      this.disableRepeatingFields()
    }
  }

  disableRepeatingFields() {
    this.repeatingFieldTargets.forEach(field => {
      field.disabled = true;
    });
    this.repeatingGroupTargets.forEach(group => {
      group.classList.add("hidden");
    });
  }

  enableRepeatingFields() {
    this.repeatingFieldTargets.forEach(field => {
      field.disabled = false;
    });
    this.repeatingGroupTargets.forEach(group => {
      group.classList.remove("hidden");
    });
  }

  setWholeDay(event) {
    if (event.target.checked == true) {
      this.disableTimeFields()
    } else {
      this.enableTimeFields()
    }
  }

  disableTimeFields() {
    this.timeFieldTargets.forEach(field => {
      field.disabled = true;
    });
  }

  enableTimeFields() {
    this.timeFieldTargets.forEach(field => {
      field.disabled = false;
    });
  }


  get defaultRecurrence() {
    return this.element.dataset.defaultRecurrence
  }
}
