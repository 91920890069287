import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    this.element.addEventListener('click', this.onClick)
  }

  disconnect() {
    this.element.removeEventListener('click', this.onClick)
  }

  onClick = (event) => {
    var matches = function(el, selector) {
      return (el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector).call(el, selector)
    }

    if (!matches(event.target, "a, a *")) {
      Turbo.visit(this.data.get('path'))
    }
  }
}
