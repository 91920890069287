import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = []

  connect() {
    this.checkStatus()
    this.startPolling()
  }

  disconnect() {
    clearTimeout(this.interval)
  }

  startPolling() {
    this.interval = setInterval(() => {
      this.checkStatus()
    }, 3000)
  }

  checkStatus() {
    let path = this.data.get('redirectPath')
    Rails.ajax({
      url: path,
      type: 'GET',
      dataType: 'json',
      success: function(response) {
        if (response.status == 'ok') {
          Turbo.visit(path)
        }
      }
    })
  }
}
