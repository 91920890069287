import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "source" ]

  copy() {
    if(navigator.clipboard) {
      navigator.clipboard.writeText(this.sourceTarget.value);
    }
    else{
      alert(this.sourceTarget.value);
    }
  }

  open() {
    window.open(this.sourceTarget.value, '_blank');
  }
}
