import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js';

export default class extends Controller {
  connect() {
    document.addEventListener("turbo:load", () => {
      tippy(document.querySelectorAll('[data-tippy-content]'), {
        touch: false,
        allowHTML: true
      })
    })
  }
}
