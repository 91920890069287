import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";
import { German } from "flatpickr/dist/l10n/de.js"

export default class extends Controller {
  static targets = [ "date", "futureDate", "time" ]
  static values = {
    id: String,
    type: String,
    defaultDate: String
  }

  connect() {
    try { this.datePicker } catch(err) {}
    try { this.futureDatePicker } catch(err) {}
    try { this.timePicker } catch(err) {}
  }

  get datePicker() {
    this.datePicker = flatpickr(this.dateTarget, {
      dateFormat: "d.m.Y",
      locale: German,
      allowInput: true,
      onOpen: function(selectedDates, dateStr, instance) {
        instance.input.readOnly = true
      },
      onClose: function(selectedDates, dateStr, instance) {
        instance.input.readOnly = false
        instance.input.blur()
      }
    });
  }

  get futureDatePicker() {
    let lead_time = this.futureDateTarget.dataset.leadTime
    this.futureDatePicker = flatpickr(this.futureDateTarget, {
      dateFormat: "d.m.Y",
      locale: German,
      minDate: new Date().fp_incr(lead_time),
      disable: [
        function(date) {
          let holidays = document.querySelector("[data-holidays]").dataset.holidays.split(",")
          let localeDateString = date.toLocaleDateString('de-CH', { year: 'numeric', month: '2-digit', day: '2-digit' })
          return ( holidays.includes(localeDateString) || date.getDay() === 0)
        }
      ],
      allowInput: true,
      onOpen: function(selectedDates, dateStr, instance) {
        instance.input.readOnly = true
      },
      onClose: function(selectedDates, dateStr, instance) {
        instance.input.readOnly = false
        instance.input.blur()
      }
    });
  }

  get timePicker() {
    let contraType = this.typeValue == 'start' ? 'end' : 'start'
    let contraPicker = document.querySelector(`[data-datepicker-id-value='${this.idValue}'][data-datepicker-type-value='${contraType}'] input`)

    this.timePicker = flatpickr(this.timeTarget, {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      defaultDate: this.defaultDateValue,
      time_24hr: true,
      allowInput: true,
      onOpen: function(selectedDates, dateStr, instance) {
        instance.input.readOnly = true
      },
      onClose: function(selectedDates, dateStr, instance) {
        instance.input.readOnly = false
        instance.input.blur()

        // NOTE: This is a quick fix to always have a 2 hour difference between start and end time on the public/bookings#new form
        // Can be refactored to be more generic and reusable
        if (contraPicker) {
          let date = new Date(`01/01/2000 ${dateStr}`).getTime()
          let contraDate = new Date(`01/01/2000 ${contraPicker.value}`).getTime()
          let difference = ((contraDate - date) / 1000 / 60 / 60)

          if (contraType == 'start') {
            if (difference > -2) {
              let newTime = new Date(date - 2*60*60*1000).toLocaleTimeString('de-CH', { hour: '2-digit', minute: '2-digit' })
              contraPicker._flatpickr.setDate(newTime)
            }
          } else {
            if (difference < 2) {
              let newTime = new Date(date + 2*60*60*1000).toLocaleTimeString('de-CH', { hour: '2-digit', minute: '2-digit' })
              contraPicker._flatpickr.setDate(newTime)
            }
          }
        }
      }
    });
  }

  disconnect() {
    try { this.futureDatePicker.destroy() } catch(err) {}
    try { this.datePicker.destroy() } catch(err) {}
    try { this.timePicker.destroy() } catch(err) {}
  }
}
