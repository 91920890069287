import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'expandable', 'collapsible' ]

  expand(event) {
    event.preventDefault();
    this.expandableTarget.classList.remove("expandable-content--collapsed")
    this.collapsibleTarget.classList.add("expandable-content--collapsed")

    this.expandableTarget.querySelectorAll('select, input, textarea').forEach(function(item, i){ item.disabled = false; });
    this.collapsibleTarget.querySelectorAll('select, input, textarea').forEach(function(item, i){ item.disabled = true; });
  }

  collapse(event) {
    event.preventDefault();
    this.expandableTarget.classList.add("expandable-content--collapsed")
    this.collapsibleTarget.classList.remove("expandable-content--collapsed")

    this.expandableTarget.querySelectorAll('select, input, textarea').forEach(function(item, i){ item.disabled = true; });
    this.collapsibleTarget.querySelectorAll('select, input, textarea').forEach(function(item, i){ item.disabled = false; });
  }

  toggle(event) {
    event.preventDefault();
    this.expandableTarget.classList.toggle("expandable-content--collapsed")
    if (this.hasCollapsibleTarget) {
      this.collapsibleTarget.classList.toggle("expandable-content--collapsed")
    }
  }
}
