import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["selectedWorker", "worker", "hiddenFormField", "filterField"]

  initialize() {
    this.currentWorkerId = Number(this.element.dataset.currentWorkerId)
    this.setWorker()
  }

  filter(event) {
    let lowerCaseFilterTerm = event.target.value.toLowerCase()

    this.workerTargets.forEach((el, _) => {
      let filterableKey = el.getAttribute("data-filter-key")

      el.classList.toggle("hidden", !filterableKey.includes( lowerCaseFilterTerm ) )
    })
  }

  select(event) {
    var matches = function(el, selector) {
      return (el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector).call(el, selector)
    }
    if (!matches(event.target, "a, a *")) {
      this.currentWorkerId = Number(event.currentTarget.dataset.employeeId)
      this.setWorker()
    }
  }

  setWorker() {
    if (this.currentWorkerId == 0) {
      this.noWorker()
    } else {
      this.selectedWorkerTarget.innerHTML=""
      var clone = document.querySelector(`[data-employee-id="${this.currentWorkerId}"]`).cloneNode(true)
      clone.removeAttribute('data-worker-select-target')
      clone.setAttribute("data-action", "click->worker-select#remove")
      this.selectedWorkerTarget.appendChild(clone)
      this.hiddenFormFieldTarget.value = event.currentTarget.dataset.employeeId
    }
  }

  remove(event) {
    this.noWorker()
    this.hiddenFormFieldTarget.value = null
    event.preventDefault()
  }

  noWorker() {
    this.selectedWorkerTarget.innerHTML = "No worker selected..."
  }
}
