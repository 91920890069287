import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["worker", "link", "filterField"]

  connect() {
    var url = new URL(window.location)

    if (url.searchParams.get("filter")) {
      this.filterWorker(url.searchParams.get("filter"))
      this.filterFieldTarget.value = url.searchParams.get("filter")
    }

    if (url.searchParams.get("service_id")) {
      this.updateLinks(url)
    }
    if (url.searchParams.get("zone_id")) {
      this.updateLinks(url)
    }
  }

  filter(event) {
    let lowerCaseFilterTerm = event.target.value.toLowerCase()
    this.filterWorker(lowerCaseFilterTerm)
  }

  filterWorker(value) {
    var url = new URL(window.location)

    if (value == "") {
      url.searchParams.delete("filter")
    } else {
      url.searchParams.set("filter", value)
    }
    history.replaceState(null, null, url)
    this.updateLinks(url)

    this.workerTargets.forEach((el, _) => {
      let filterableKey = el.getAttribute("data-filter-key")
      el.classList.toggle("hidden", !filterableKey.includes( value ) )
    })
  }

  setZone(event) {
    var value = event.target.value
    const url = new URL(window.location);

    if (value == "") {
      url.searchParams.delete("zone_id");
    } else {
      url.searchParams.set("zone_id", event.target.value)
    }

    history.replaceState(null, null, url)
    window.location.href = window.location.href
  }

  setService(event) {
    var value = event.target.value
    const url = new URL(window.location);

    if (value == "") {
      url.searchParams.delete("service_id");
    } else {
      url.searchParams.set("service_id", event.target.value)
    }

    history.replaceState(null, null, url)
    window.location.href = window.location.href
  }

  updateLinks(url) {
    var parameters = "?" + url.searchParams.toString();

    this.linkTargets.forEach((el, _) => {
      if (el.href.includes("?")) {
        el.href = el.href.split("?")[0] + parameters;
      } else {
        el.href = el.href + parameters;
      }
    })
  }
}
